import React, {useEffect, useCallback} from 'react';
import '../styles/modal-content.css'
export default function TermsAndConditionsContent(props) {
    const {onClickCrossButton} = props;
    const escFunction = useCallback((event) => {
        const modalWindow = document.getElementById("modal-id");
        if(event.keyCode === 27) {
            onClickCrossButton();
        }
        if(event.target == modalWindow){
            onClickCrossButton();
        }

    }, []);

    useEffect(() => {
        document.addEventListener("keydown", escFunction, false);
        window.addEventListener("click", escFunction, false);
        return () => {
            document.removeEventListener("keydown", escFunction, false);
            window.removeEventListener("click", escFunction, false);
        };
    }, []);
    return (
        <div className="modal" id="modal-id">
        <div className="modal-content">
            <div className="cross-button-container">
                <span onClick={onClickCrossButton} className="modal-cross-button"></span>
            </div>
            <h3>
                Terms and conditions<br/><br/>
            </h3>

            <h2>Last updated: June 17, 2021</h2>
            <p>
            Welcome to Nested. Below, we describe the terms and conditions applicable to a booking and stay with Nested, as well as the terms and conditions applicable to the use of our website.<br/><br/>

            When we use the term “Site,” we are referring to our www.nested.house.com website, together with any affiliated mobile applications, as well as to any content, functionality, and services offered on or through this website and/or any affiliated mobile applications.<br/><br/>

            The Site is owned and operated by Nested House, Inc. (who we refer to, together with our affiliates, as “Nested,” “us,” “we,” or “our,” depending on the context). When we refer to “you” and “your” in these terms, we are referring to you as a visitor to and user of the Site and, as applicable, to you as a visitor to on of our Nested properties.<br/>
            </p>

            <h2>Acceptance of Terms</h2>
            <p>
            OU SHOULD CAREFULLY READ THESE TERMS. By accessing, browsing, or otherwise using the Site, you are creating a binding contract between you and us, and you are acknowledging that you have read, understood, and agreed to be bound by these terms.<br/><br/>

            We may make changes to the Site or these terms at any time. Any modifications will be effective when the changes are posted to the Site. You can review the most current version of these terms at any time by accessing the section of the Site labeled [Terms of Use], and you can determine when this Privacy Policy was last revised by referring to the “Last Updated” legend at the top of these terms. You are expected to check these terms from time to time to take notice of any changes. <b>You understand and agree that your continued access to or use of the Site after any posted modification to these terms indicates your acceptance of the modification, even if you did not take the time to read the modification.</b><br/><br/>
            </p>

            <h1>BOOKING AND STAY TERMS</h1>
            <h2>Nested Units</h2>
            <p>
            “Nesteds,” also known as “Nested Units,” “Nested Houses,” “Nested Cabins,” “Tiny Houses,” “Nested Outposts,” or “Nested Tiny Houses” are non-motorized recreational vehicles that are built to recreational vehicle standards and kept in highway ready condition. We use these terms interchangeably throughout these terms to refer to our properties, but property features, guest benefits and services may vary among particular properties. You can obtain more information about a particular Nested at the time of booking.<br/>
            </p>

            <h2>General Statement of Caution</h2>
            <p>
            Nesteds are located on campgrounds that are often very rural, mostly undeveloped plots of land in the woods. Guests are completely responsible for their own safety and for having basic wilderness survival skills (e.g. do not approach wild animals, do not leave food out, etc.). Nested is not liable for bodily injury (including death) or damage to personal property, including vehicles, due to exposure to inclement weather or rural, rugged settings. By booking a Nested, guests recognize and accept this responsibility and affirm they have these general skills.<br/><br/>

            In addition, while people enjoy our Outposts for their secluded settings, our cabins and related facilities are shared by all guests. Although we do our best to keep all facilities safe and clean, including enhanced cleaning procedures to address COVID-19, Nested is not liable for illness that may result in whole or in part from the use of our facilities. Guests are expected to observe prevailing CDC recommendations during their stay (including social distancing) and take other appropriate steps to maintain their health. By booking a Nested, guests recognize and accept that responsibility for their physical health and informing themselves of such protocols is fully their own.<br/>
            </p>

            <h2>Nested Booking</h2>
            <p>
            A Nested is booked upon receipt of the booking payment. This payment should be made by credit card through the Nested website. Your booking is not confirmed until Nested receives the payment and you have received the confirmation email. This confirmation email contains booking details, contact details, and other arrival and departure instructions. Furthermore, the guest will receive an email one week before check-in regarding the exact location of the Nested. One day before your check-in date, Nested may run a $1 authorization charge to the card used for your booking. If the charge is not authorized, you will be contacted by email to update your payment method.<br/>
            </p>

            <h2>Group Bookings</h2>
            <p>
            Our mission is to offer simple escapes for people to unplug and connect with loved ones. While we do our best to accommodate requests for cabins located close to each other, Nested is not the place for your next party.<br/><br/>

            However, we do offer custom full-site buyouts for groups. Note that any reservation requesting more than three cabins requires a full-site buyout. Contact our team at hi@nested.house to learn more.<br/>
            </p>

            <h2>Service Fees</h2>
            <p>
            Bookings may include a nightly service fee, plus applicable tax (“Service Fee”). The Service Fee will be added to the nightly room rate, and includes coffee, tea, firewood and fire kindling during a stay at a Nested Outpost. Reservations without a Service Fee will be governed by the Nested Provisions terms described below.<br/><br/>

            Promotions, offers, discount coupons, vouchers, or codes may not be applied to the Service Fee, unless the terms for such promotion, offer or discount explicitly allow for it. This means that when you book a stay, you will not be eligible for an additional discount on the Service Fee on that booking unless specifically stated in the terms of the discount.<br/><br/>

            Bookings made with the Service Fee are subject to all cancellation and early departure policies and fees, and other payment terms as applicable.<br/><br/>

            We may change the Service Fee at any time, with or without notice, including the pricing and associated benefits, and cancellation terms. We may communicate these changes to you by email, and any changes will be posted in an update to these terms.<br/><br/>

            The Service Fee has no predetermined end date and may continue until terminated by Nested, which may happen at any time at Nested’s discretion, with or without notice.<br/>
            </p>

            <h2>Gift Cards</h2>
            <p>
            Nested offers the purchase of gift cards through our Site only. The gift card includes a code that may be used to book a Nested stay in any available cabin of the recipient’s choosing. All gift card purchases are final sale and do not expire. Gift cards cannot be applied to Nested Often Packs or to purchase a different gift card.<br/>
            </p>

            <h2>Maximum Stay</h2>
            <p>
            No guest may book more than seven consecutive nights at any Nested location.<br/>
            </p>

            <h2>Cancellation Policy</h2>
            <p>
            Cancellations and changes to your booking can be made through your Nested account. If you booked with a Nested Often Pack or promo code, cancellations and changes to your booking can be made via email or by phone. In the event of cancellation of a fully paid booking, there is a cancellation charge corresponding to the number of days it was made, prior to the day of arrival: At least 15 days prior = no cancellation charge (full refund). 8-14 days prior = cancellation charge equals 50% of the total booking amount (partial refund). 7 or less days prior = cancellation charge equals 100% of the total amount paid (no refund). In the event that the guest changes the dates, including adding or removing a date, it will be considered a new booking and will be subject to the standard cancellation procedures. If, after receipt of the confirmation email, Nested is unable to provide the guest with the property that they booked or a property with similar or better characteristics, the guest will be refunded or credited subject to the following conditions: 100% of the total booking amount.<br/><br/>

            Fire bans happen occasionally at some of our Outposts due to wildfire worries at the state or county level. The use of your fire pit, or burning fires of any kind, is not permitted during these events. There are no exceptions to our rescheduling/cancellation policies while the fire bans are active. By booking a Nested, guests agree to this policy.<br/>
            </p>

            <h2>Damages</h2>
            <p>
            Please note that by booking a Nested and accepting these terms, guests agree that they are liable for any damages caused by themselves or their accompanying party.<br/>
            </p>

            <h2>Preparation For Arrival</h2>
            <p>
            Guests are expected familiarize themselves with Nested’s policies and get in touch with our team if they have any questions before check-in. We will prepare each cabin for check-in and can be prepared to help with arrival. Each confirmation email states contact information for Nested, should guests need it.<br/>
            </p>

            <h2>Check-In</h2>
            <p>
            Cabins will be available for check-in at 3:00 PM. If guests arrive for check-in after 10:00 PM, please note that quiet hours are in effect. Guests may be subject to a fee for loud disturbances during quiet hours.<br/>
            </p>

            <h2>Check-Out</h2>
            <p>
            Guests should leave the tiny house before or at 11:00 AM. Leaving after 11:00 AM without prior authorization will be penalized. If guests need to leave the location at a later time, please contact Nested prior to arrival and ask if this is possible. We try to meet guest requests, but cannot guarantee late checkouts.<br/>
            </p>

            <h2>Departure</h2>
            <p>
            On the day of departure guests should leave the cabin clean and tidy, and lock the door after making sure that they have all their personal belongings. The security code will then be changed remotely for the next guest. Nested is not responsible for returning any personal belongings left behind after check-out.<br/>
            </p>

            <h2>Lost and Found</h2>
            <p>
            We understand things get left behind; however, Nested is not responsible for lost or stolen items left on property. It is the guests' responsibility to ensure all personal belongings are collected upon check-out.<br/>
            </p>

            <h2>Drones</h2>
            <p>
            Drones are not allowed on Nested properties.<br/>
            </p>

            <h2>Cleaning</h2>
            <p>
            The Nested will be cleaned prior to each arrival. Before leaving, the guest is obliged to leave the Nested in perfect conditions, i.e. all waste should be correctly disposed of and the tiny house tidied. If not, they may be subject to an additional cleaning charge.<br/>
            </p>

            <h2>Campfire, Littering, and Noise Pollution</h2>
            <p>
            Safety and ensuring a good experience for all of our guests is important to us. We ask that guests never leave a campfire unattended and to never leave food and/or trash outside. We also ask that guests don't play music outside of their cabins and to avoid yelling or making unnecessary noise. Violations are subject to a $100 charge, donated to local conservation groups.<br/> 
            </p>

            <h2>Number and Identity of Guests</h2>
            <p>
            Guests should inform Nested of the number of guests in the booking. Only those persons indicated in the booking made by the client may enter the tiny house, unless an alternative agreement has been reached with Nested. The number of guests using the Nested should not exceed the number assigned to each tiny house, except for in the case of infants under the age of two. No tents are allowed.<br/><br/>

            Individuals booking a reservation on behalf of a client are encouraged to use the contact information of their client and otherwise assume the responsibility of relaying all reservation instructions and details to their client.<br/>
            </p>

            <h2>Minimum Age</h2>
            <p>
            All guests staying in the cabin must be 18 years of age or older, unless accompanied by a parent or legal guardian. Guests are required to use a credit card in their own name in order to book a cabin.<br/><br/>

            No illegal substances or underage drinking are permitted in the Nested cabins or on Nested property. Nested will not be held responsible for underage drinking, and violations of this policy will result in immediate expulsion from the Nested premises without refund, and the involvement of law enforcement.<br/>
            </p>

            <h2>Pets</h2>
            <p>
                Dogs are allowed on the property for a $40 fee per stay, but must be on
                a leash when outside. Guests may not leave their dogs inside their
                cabins if they leave the area. All litter must be cleaned up
                immediately, and one warning will be given to noisy dogs before he or
                she will be requested to leave. Guests are totally liable for your dog
                should there be any claim. All guests must be aware that dogs may have
                occupied the cabin previous to their own stay. Pets other than dogs are
                not permitted in the cabins. Nested is not responsible for any negative
                reactions regarding pet allergies or otherwise.
                <br />
            </p>
            <h2>Conduct in Nesteds</h2>
            <p>
                Unruly, disruptive and destructive behavior and criminal activity is
                prohibited. Guests in a Nested cabin should be aware that if loud music
                is played or a party is held, and the neighbors complain and/or police
                are called, they may be immediately removed from the tiny house
                regardless of the time, day or night.
                <br />
            </p>
            <h2>Weapons</h2>
            <p>
                Weapons of any kind are not allowed on Nested property. This includes,
                but is not limited to: standard firearms, air guns, deterrent devices
                such as tasers or pepper spray, ammunition of any kind, and imitation
                firearms.
            </p>
            <h2>Internet</h2>
            <p>
                Internet services are not provided for the use of Nested guests in any
                case.
            </p>
            <h2>Smoking</h2>
            <p>
                Smoking, including the use of e-cigarettes, is not permitted inside the
                cabins and must be done at a minimum distance of 25 feet from all
                buildings. Litter is not permitted on the land and guests must clean up
                all cigarette butts. Guest will be fined a fee of $150 for each night
                that the cabin is unable to be occupied after stay if said guest is
                found smoking within the cabin.
            </p>
            <h2>Property Access During Winter Months</h2>
            <p>
                Nested cabins are offered for rental during the winter months and have
                road access and receive snow clearance services. However, guests are
                advised that following any major snowfall, snow plow providers are
                contracted to clear local roads in a predetermined order. Consequently,
                plowing at a property may be delayed for several hours or, in severe
                storm conditions, days. In the unlikely event that a guest is unable to
                leave a property on the planned departure date, the guest will not be
                liable for any additional accommodation charges. Should a lack of snow
                clearance prevent a client from reaching a property, the guest will
                receive a refund of the booking fee for the day(s) the property was
                inaccessible. However, Nested will not be held responsible for any
                losses, additional expenses or penalties incurred as a result of being
                unable to arrive at or leave a property on time due to a lack of snow
                clearance. Guests are also reminded to ensure that they and their
                vehicles are suitably prepared and equipped for driving in the wintry
                conditions likely to be encountered during their visit to the property.
            </p>
            <h2>Equipment and Facilities Provided</h2>
            <p>
                Nested reserves the right to move any Nested unit at any time. If a
                Nested unit must be moved in advance of an upcoming booking, we will
                make every attempt to inform the guest of the location change before
                their stay.
                <br />
                <br /> All equipment and facilities are provided at the discretion of
                Nested and every attempt is made to ensure that such equipment is in
                working order for the duration of the rental period. Should a breakdown
                or some other situation occur that renders a non-essential element
                unusable, Nested does not take responsibility for replacing or
                refunding the guest for the lack of use of these equipment or
                facilities. The equipment and facilities referred to include (but are
                not exclusive to) such items as stove, toilet, and sink. The guest must
                report any inoperative or defective equipment to Nested promptly. We
                will make every reasonable effort to have repairs made as soon as
                possible. While every attempt will be made to ensure that all the
                advertised equipment and appliances are in working order at the
                commencement of a rental period, no reduction of rent, rebate, or refund
                will be issued for a mechanical failure of any appliances.
            </p>
            <h2>Nested Provisions</h2>
            <p>
                All guests have the option to buy from the Nested Provisions during
                their stay. These purchases are made in addition to the booking fee and
                will be charged automatically upon checkout, using the method of payment
                saved in the guest account. A receipt for this transaction will be sent
                to the guest upon charging the card, and guests are able to contact
                Nested at any time with questions about these charges.
            </p>
            <h2>Wildlife</h2>
            <p>
                Nesteds are placed on plots of land where wildlife may be present. When
                booking a cabin, the guest agrees to take appropriate safety measures to
                prevent negative interactions with wildlife in the area. This includes,
                but is not limited to, not engaging with wild animals, keeping food out
                of reach, keeping the site clean, and securing all personal items. Wood
                ticks, mosquitos, and other insects may also be present at the site –
                all guests are expected to take necessary steps of precaution against
                these insects. Guests are also not permitted to harm the land or the
                natural habitat of any wildlife in any way. Nested is not liable for
                any personal injury due to wildlife or insects.
            </p>
            <h2>Rules</h2>
            <p>
                All guests agree to read and abide by all rules specific to their
                booking, which may vary by Nested unit and location. These rules must
                be read and agreed to before check-in. Nested will also place the rules
                inside each cabin, accessible to guests. If rules are not abided by,
                Nested reserves the right to ask guests to leave the property and pay
                for any necessary damages as a result of the guests’ disregard for these
                rules.
            </p>
            <h2>Referrals</h2>
            <p>
                Registered Nested guests may be eligible for a referral credit when
                they send someone a referral email and the referred guest completes his
                or her first purchase. To qualify for the credit, the referred guest
                must click on the referrer’s unique link, create a new Account, and
                place an order via the Site. Referral credit is granted as Nested
                booking credit to the referrer’s account once the referred customer has
                paid for a booking. The credit is non-transferable and may be revoked if
                the referred customer’s booking is cancelled.
                <br />
                <br /> We reserve the right to review, investigate, and remove referral
                credit as well as suspend users or accounts in instances we identify
                behavior we believe to be abusive, fraudulent, or not aligned with the
                spirit of the program, such as:
                <ul>
                <li>
                    Attempts to gain credit through self-referral by opening multiple
                    accounts using different email addresses or social media accounts
                </li>
                <li>
                    Credit gained by referring guests through paid advertising,
                    sponsored links, posting to commercial sites, or spamming
                </li>
                <li>
                    Credit gained by referring guests who cancel their reservations once
                    the credit has been applied to the referring guests’ account{" "}
                </li>
                <li>Violation of these Terms</li>
                </ul>
                You are encouraged to share your referral link with friends and family
                and through social media outlets. However, you may not include Nested
                trademarks without permission, present yourself as an employee or
                consulting of Nested,or engage in activity that may be regarded as
                misleading to Nested customers. We reserve the right to suspend our
                referral program at any time.
            </p>
            <h2>Credits</h2>
            <p>
                Nested sometimes issues credits that can be used to pay for your
                reservation in whole or in part. Credits may take the form of credits
                gifted to guests through our Gift Card program, purchased through our
                Nested Often Packs purchase page, or promotional credits we issue from
                time-to-time. Credits cannot be combined with other credits unless
                explicitly stated when issued. Nested retains the sole right to decide
                how credits can or cannot be combined to pay any amounts owed to
                Nested. By creating an account or making a transaction on our website,
                you agree that Nested has sole discretion on how credits can be used.
                Credits may not be applied to purchase add ons or other credits, for
                example gift cards credits cannot be used to purchase Nested Often
                Packs.
            </p>
            <h2>Nested Often Program</h2>
            <p>
                After you have stayed for seven (7) paid nights at any Nested Outpost
                on or after the implementation of the Nested Often Program on November
                18, 2019, you will earn one (1) free night (“Free Night”) redeemable at
                any eligible Nested Outpost, subject to these Program Terms. You can
                redeem your Free Night online, or you can contact Nested to have one of
                our Nested team members make your booking for you.
                <br />
                <br />
                You must pay for any taxes, fees, incidental charges, gratuities, meals
                and any other costs associated with your Free Night reservation and
                stay.
                <br />
                <br />
                Stays that count towards your Free Night will be accrued upon check out
                and receipt of payment, and can take up to three (3) hours following
                check out to be posted in the guest account. In order for bookings to
                count towards the Free Night, they must be paid stays, and may include
                stays paid with gift cards, discounts, multi-night packs, and select
                comped night credits. Free and comped promotional stays as an artist
                fellow, brand partner, influencer, and Nested employee, and other free
                stays, do not count toward accrual of a guest’s Free Night.
                <br />
                <br />
                Free Night stays are subject to all standard Nested booking terms and
                conditions, which may be found at Nested.house/terms. Free Night stays
                do not accrue toward future Free Nights. Free Nights are
                non-transferable, have no cash value, and are not redeemable for cash.
                <br />
                <br />
                You may not combine your Free Night with any other promotions, offers,
                or discount coupon, voucher or code, unless the terms for such
                promotion, offer or discount explicitly allow for it. This means that
                when you book a stay and redeem your Free Night, you will not be
                eligible for an additional discount on that booking unless specifically
                stated in the terms of the discount.
                <br />
                <br />
                Free Night stays are subject to all cancellation and early departure
                policies and fees, and other payment terms as applicable. Late
                check-outs must be requested and are subject to availability. If
                reservation cancellation occurs 8+ days prior to check in, the Free
                Night will be refunded to the user account. If reservation cancellation
                occurs within 7 days of check-in, the credit will be forfeited.
                <br />
                <br />
                We may change our terms and conditions at any time, with or without
                notice, including the rules for earning and redeeming Free Nights, the
                potential establishment of Program tiers and their qualification
                requirements and associated benefits, and the maximum value of a Free
                Night. We may communicate these changes to you by email, and we will
                update the Nested Often Program Terms on our website.
                <br />
                <br />
                The Nested Often Program has no predetermined end date and may continue
                until terminated by Nested, which may happen at any time at Nested’s
                discretion, with or without notice. If we do end the Nested Often
                Program, you may immediately lose the right to accrue additional Free
                Nights, and will have thirty (30) days from when we announce its closure
                to redeem any Free Nights you have in your account. After that date, you
                will forfeit your accrued Free Nights with no right to compensation.
                <br />
                <br />
                Nested reserves the right to amend, add, delete or otherwise change any
                of the terms, benefits, rules or procedures of the Nested Often Program
                at its sole discretion, with or without notice, and even if those
                changes affect the accumulation or value of your Free Nights. By
                continuing to accrue nights and/or redeem Free Nights, you accept and
                agree to any changes to these Nested Often Program Terms. You are
                responsible for reviewing these Terms periodically to keep up to date on
                any changes that we may make. The most current version of the Terms is
                available on our website at https://Nested.house/terms/. You are
                responsible for maintaining up-to-date contact information in the
                Nested booking and accounts management system.
                <br />
                <br />
                Participation in the Nested Often Program is only eligible to guests of
                majority age, and is void where prohibited by law in the participating
                guest’s home jurisdiction.
                <br />
                <br />
                Nested reserves the right to suspend or terminate participation in the
                Nested Often Program of any guest who Nested believes, or reasonably
                suspects, in its sole discretion, has (1) violated applicable federal,
                state or local laws, regulations or ordinances; (2) breached these
                Program Terms or violated the company’s intended use of the Nested
                Often Program; (3) engaged in dishonest or fraudulent behavior or other
                material misconduct in connection with participation in the Nested
                Often Program, a stay at a Nested Outpost, or towards a Nested team
                member; (4) or failed to pay any amounts due to Nested. The suspension
                or termination of a guest’s participation in the Nested Often Program
                may result in the loss of accumulated benefits, including Free Nights
                already earned but not used.
                <br />
                <br />
                Often Program; (3) engaged in dishonest or fraudulent behavior or other
                material misconduct in connection with participation in the Nested
                Often Program, a stay at a Nested Outpost, or towards a Nested team
                member; (4) or failed to pay any amounts due to Nested. The suspension
                or termination of a guest’s participation in the Nested Often Program
                may result in the loss of accumulated benefits, including Free Nights
                already earned but not used.
                <br />
            </p>
            <h2>Newsletter</h2>
            <p>
                By booking a Nested, guests also agree to receive our newsletter.
                Guests may unsubscribe at any time.
            </p>
            <h2>Limitation of Liability</h2>
            <p>
                Nested shall not be responsible for any direct or indirect damages
                caused to the any Nested Outpost as consequence of use. This includes
                damages, insurance, loss due to fire, theft, and misconduct.
                <br />
                <br />
                In the event that a guest fails to comply with any terms applicable to
                their booking and/or stay, Nested reserves the right to ask the guest
                to leave the unit and property, and the guest in question shall not be
                entitled to any form of compensation. Upon booking any property with
                Nested guests automatically accept our terms, which we expect them to
                comply with.
            </p><br/>






            <h1>GOVERNING LAW, CHOICE OF FORUM, JURY AND CLASS ACTION WAIVER<br/></h1>
            <h2>Please read the following section carefully, because it limits the manner in which you can seek relief from us.<br/></h2>
            <p>
            The laws of the State of New York (without giving effect to its conflicts of law principles) govern all matters arising out of or relating to these terms, including, without limitation, their validity, interpretation, construction, performance, and enforcement, or otherwise arising out of or relating to your booking or stay with us. All legal proceedings arising out of or in connection with these terms, your booking, or stay, shall be brought solely in New York, New York. YOU AND WE HEREBY WAIVE TO THE FULLEST EXTENT PERMITTED BY LAW ANY RIGHT TO TRIAL BY JURY IN ANY ACTION, SUIT OR RELATED PROCEEDING BROUGHT TO ENFORCE, DEFEND OR INTERPRET ANY RIGHTS OR REMEDIES ARISING UNDER, RELATING TO OR IN CONNECTION WITH THESE TERMS.<br/><br/>FURTHER, YOU AGREE THAT YOU MAY ONLY RESOLVE DISPUTES IN YOUR INDIVIDUAL CAPACITY, AND MAY NOT BRING A CLAIM AS A PLAINTIFF OR AS CLASS MEMBER IN A CLASS, CONSOLIDATED, OR REPRESENTATIVE ACTION. CLASS ARBITRATIONS, CLASS ACTIONS, PRIVATE ATTORNEY GENERAL ACTIONS, AND CONSOLIDATION WITH OTHER ACTIONS ARE NOT ALLOWED.<br/>
            </p>

            <h1>ADDITIONAL SITE TERMS<br/></h1>
            <h2>Accounts<br/></h2>
            <p>
            Users of the Nested website may be required to create an account to make purchases and access other features of the website. An account is created when a user registers via email, social media or other means. Upon registration, a user acknowledges that their email address and other data may be used to login to to the site in the future.<br/><br/>

            Whenever you create an account or log in to an account tied to your email address, sign up to receive any newsletters or similar materials, make a booking, contact us through the Site, or otherwise interact with us through the Site, whether as a guest user or an account holder, you represent, warrant, and agree that: (a) you are at least 18 years old, (b) you are using your actual identity, (c) you have provided only true, accurate, current and complete information to us, and (d) you will maintain and promptly update the information that you provide to keep it true, accurate, current, and complete.<br/><br/>

            f you have an account, you agree not to disclose or share your login information to or with any third party. You also agree that you will be solely responsible for the maintenance and security of your login information, and that you will be solely responsible for any activities conducted on or through the Site, including any bookings made, in connection with your login information, regardless of whether or not you are the individual who undertakes such activities. This includes any unauthorized access and/or use of your account, your computer, or your mobile device.<br/>
            </p>
            <h2>Your Responsibility When Using The Site; Interaction With Social Media<br/></h2>
            <p>
            You agree to use the Site, and to share information from the Site, only for its intended purpose as described on the Site and in these terms. Without limiting that general requirement, you agree that you will not: (a) use any automated means (such as robots, spiders, scripts, or other devices or programs) to access the Site or collect any content; (b) engage in any “screen scraping,” “database scraping” or similar activities to obtain any content from the Site; (c) accumulate or index, directly or indirectly, any content or portion of the Site for any commercial purpose whatsoever; or (d) otherwise use the Site to engage in any illegal activity, or to engage in conduct that is defamatory, libelous, threatening, or harassing, or that infringes on a third party’s proprietary rights.<br/><br/>

            Further, you understand that the Site strives to protect its security and integrity and those of its users. You therefore agree that you will not attempt to elude any security systems in place on the Site, or use the Site in any manner that may adversely affect the functionality of the Site, the Site’s availability to other users, or the rights of other users of the Site.<br/><br/>

            We reserve all rights to monitor the Site for any reason, including to evaluate whether any use of the Site is, in our sole discretion, consistent with these terms. However, we assume no responsibility or liability arising from the use of the Site by our users. We are under no obligation to enforce these terms on your behalf against another user.<br/><br/>

            We may from time to time include features on the Site that allow you to share information from the Site, or provide comments and feedback regarding the Site. Any comments and feedback will be subject to these terms and to any other policies and guidelines we may publish from time to time regarding user content. In addition, these interactive features may link to, or require you to log into, one of your social media, networking, or social sharing accounts accounts (such as Facebook, Instagram, Twitter, LinkedIn, Google, Spotify, etc.), or may otherwise access or use your social media or other account information. We are not in control of your social media account(s) or how the social media service provider uses your information, which is governed by the setting and privacy policies of the social media account service providers.<br/><br/>

            We may suspend or terminate your access to the Site at any time, with no liability to you, including if we are investigating any suspected noncompliance with these terms.<br/>
            </p>
            <h2>E-mail<br/></h2>
            <p>The Site provides users with an opportunity to open a communication channel with us via e-mail. If you choose to use these features, you grant us permission to communicate with you by e-mail for any purposes related to your use of the Site, including for any disclosures, system messages and other marketing purposes. We will use our best efforts to honor any request to opt out of marketing messages, but under no circumstances will we have any liability for sending you any e-mail.<br/></p>
            <h2>Links To Other Websites<br/></h2>
            <p>We and others may provide links to web pages, web sites, and various resources or locations on the web (collectively, “Third Party Sites”). Links to Third Party Sites are provided only for the convenience of users of the Site. We do not operate, control, endorse or guarantee any Third Party Sites. When you access any Third Party Site through a link posted on the Site, please carefully read the terms and conditions of use, privacy policy and other policies of such Third Party Site. Our policies do not apply to any Third Party Site.<br/><br/>

            YOU AGREE THAT YOUR USE OF ANY THIRD PARTY SITE IS AT YOUR SOLE RISK AND WITHOUT WARRANTIES OF ANY KIND BY US, EXPRESSED, IMPLIED OR OTHERWISE. UNDER NO CIRCUMSTANCES ARE WE LIABLE FOR DAMAGES ARISING FROM ANY TRANSACTION BETWEEN YOU AND ANY THIRD PARTY SITE OR FOR ANY INFORMATION APPEARING ON THIRD PARTY SITES.<br/></p>
            <h2>Intellectual Property<br/></h2>
            <p>The Site is owned by us, and you agree that, as between you and us, we own or otherwise have all proprietary rights to all content included on the Site. We also own or otherwise have all proprietary rights to the copyrights, trademarks, service marks, trade names and other intellectual and proprietary rights throughout the world associated with the content and the Site, which are protected by applicable intellectual and proprietary rights and laws.<br/><br/>

            You may not reproduce, publish, distribute, display, modify, create derivative work from, or exploit in any way, in whole or in part, any content without our prior express written consent.<br/><br/>

            “Nested,” “Nested HOUSE,” and all related names, logos, products and service names, designs and slogans are trademarks of ours. You may not use such marks without our prior written permission. Further, you may not use any metatags, meta elements, “hidden text” or other equivalents using the names “Nested, “Nested House”, “www.Nested.house” or any other colorable equivalent without our prior written authorization.<br/><br/>

            References on the Site to any third-party products or services by trade name, trademark, manufacturer, supplier, vendor, or otherwise does not constitute or imply endorsement, sponsorship, or recommendation by or thereof, or any affiliation therewith, by us.<br/></p>
            <h2>Indemnity<br/></h2>
            <p>You agree to indemnify and hold us, and our directors, officers, affiliates, volunteers, agents, employees, licensors and partners harmless from any claim or demand, including reasonable attorneys’ fees, made by any third party due to or arising out of any content you submit, post to, or transmit through the Site, your use of the Site, your stay at any of our properties, your violation of these terms or your violation of any rights of any third party.<br/></p>
            <h2>Disclaimer<br/></h2>
            <p>YOU ACKNOWLEDGE, AGREE AND UNDERSTAND THAT:<br/><br/>

            WE ATTEMPT TO DISPLAY THE MATERIALS AND INFORMATION YOU VIEW ON THE SITE AS ACCURATELY AS POSSIBLE. HOWEVER, WE DO NOT GUARANTEE THE ACCURACY OF SUCH MATERIALS AND INFORMATION, AND ARE NOT RESPONSIBLE FOR ANY CLAIMS ARISING OR RESULTING FROM YOUR RELIANCE THEREON.<br/><br/>

            THE SITE AND ALL INFORMATION, CONTENT, MATERIALS, PRODUCTS AND SERVICES INCLUDED ON OR OTHERWISE MADE AVAILABLE THROUGH THE SITE ARE PROVIDED ON AN “AS IS” AND “WHERE AVAILABLE” BASIS AND WITHOUT WARRANTIES OF ANY KIND. TO THE FULLEST EXTENT PERMITTED BY LAW, WE DISCLAIM ALL WARRANTIES, EXPRESS OR IMPLIED, INCLUDING IMPLIED WARRANTIES OF MERCHANTABILITY AND FITNESS FOR A PARTICULAR PURPOSE AND NON-INFRINGEMENT.<br/><br/>

            WE DO NOT REPRESENT OR WARRANT THAT THE SITE WILL BE UNINTERRUPTED OR ERROR-FREE, THAT ANY DEFECTS WILL BE CORRECTED, OR THAT THE SITE, INFORMATION, CONTENT, MATERIALS, PRODUCTS OR SERVICES INCLUDED ON OR OTHERWISE MADE AVAILABLE THROUGH THE SITE, THE SERVERS THAT MAKE THE SITE AVAILABLE, OR E-MAIL OR OTHER CORRESPONDENCE SENT THROUGH THE SITE, ARE OR WILL BE FREE OF VIRUSES OR ANYTHING ELSE HARMFUL. WE DO NOT MAKE ANY WARRANTIES OR REPRESENTATIONS REGARDING THE USE OF ANY INFORMATION, CONTENT, MATERIALS, PRODUCTS AND SERVICES INCLUDED ON OR OTHERWISE MADE AVAILABLE THROUGH THIS SITE IN TERMS OF THEIR CORRECTNESS, ACCURACY, ADEQUACY, USEFULNESS, RELIABILITY OR OTHERWISE.<br/><br/>

            CERTAIN STATE LAWS MAY NOT ALLOW LIMITATIONS OR EXCLUSIONS ON WARRANTIES, SO THE ABOVE LIMITATIONS MAY NOT APPLY TO YOU.<br/></p>
            <h2>Limitations on Liability<br/></h2>
            <p>WE WILL NOT BE LIABLE FOR ANY DAMAGES OR INJURY CAUSED BY YOUR USE OF THE SITE, INCLUDING ANY FAILURE OF PERFORMANCE, ERROR, OMISSION, INTERRUPTION, DEFECT, DELAY IN OPERATION OF TRANSMISSION, COMPUTER VIRUS, OR LINE FAILURE. WE WILL NOT BE LIABLE FOR ANY DAMAGES OR INJURY, INCLUDING INDIRECT, INCIDENTAL, SPECIAL, PUNITIVE, EXEMPLARY OR CONSEQUENTIAL DAMAGES (INCLUDING LOST PROFITS AND DAMAGES THAT RESULT FROM INCONVENIENCE, DELAY, OR LOSS OF USE) THAT RESULT FROM THE USE OF, THE INABILITY TO USE, OR RELIANCE UPON, THE SITE OR ANY SERVICES OBTAINED ON OR THROUGH THE SITE, EVEN IF THERE IS NEGLIGENCE BY US OR BY OUR AUTHORIZED REPRESENTATIVE, OR WE HAVE BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES, OR ANY OF THE ABOVE. THE ABOVE LIMITATION OR EXCLUSION MAY NOT APPLY TO YOU TO THE EXTENT THAT APPLICABLE LAW MAY NOT ALLOW THE LIMITATION OR EXCLUSION OF LIABILITY FOR SUCH DAMAGES.<br/></p>
            <h2>Privacy Policy<br/></h2>
            <p>We are committed to protecting your privacy and security and have explained in detail the steps we take to do so. Please read our Privacy Policy at www.Nested.house, for more information on the collection and use of information on the Site.<br/></p>
            <h2>WEBSITE AND MOBILE APPLICATION ACCESSIBILITY POLICY<br/></h2>
            <p>Nested is committed to facilitating the accessibility of our Site to all visitors, including people with disabilities. Nested is making efforts to maintain and increase the accessibility of its Site to ensure that persons with disabilities have full and equal enjoyment of Nested’s electronic goods, services, facilities, privileges, advantages, and accommodations. Nested has established this Website and Mobile Application Accessibility Policy to further ensure we provide goods, services, information and a retail experience to all of our customers in a manner consistent with this core value.<br/><br/>

            Nested has retained a third party digital accessibility and assistive technology testing partner to assist Nested in adopting and implementing procedures to the relevant portions of the World Wide Web Consortium’s Web Content Accessibility Guidelines 2.1 Level AA (WCAG 2.1 AA) as its web accessibility standard for its Websites and Mobile Applications and continues to make such compliance of its Websites and Mobile Applications a key focus of its software development and design efforts. These efforts have also brought Nested into further conformance with Title III of the American with Disabilities Act (ADA).<br/><br/>

            Nested continues to work with its accessibility consultant to conduct periodic Website and Mobile Applications accessibility audits and to incorporate ongoing and specific recommendations to facilitate and strengthen accessibility for its Websites and Mobile Applications and conformance with current WCAG 2.1 AA standards. In a dynamic ever-changing technology environment, Nested understands the importance of continually testing its digital properties to ensure consistent experiences are delivered. Nested tests and confirms the accessibility of each Website and Mobile Application in advance of an initial launch, if applicable, and on a periodic basis thereafter to monitor and test for conformity to WCAG 2.1 AA, Title III of the ADA and other accessibility guidelines. Nested uses manual, functional and technical methods to conduct the evaluations, which include people with disabilities, to enhance the digital customer experience for people of all abilities. Nested provides regular training and education to employees who are central to its Website software and design development on current website accessibility guidelines and principles.<br/><br/>

            Nested has adopted this Website and Mobile Applications Accessibility Policy to support its commitment to the accessibility of its websites and mobile applications both now and on an ongoing basis. If you find a part of the website or mobile applications that you feel does not conform to Level AA of the WCAG, please use one of the methods below to let us know so that we can rectify it.<br/>
            <ul>
                <li>EMAIL: hi@nested.house</li>
                <li>WEBSITE: https://nested.house</li> 
            </ul>
            We appreciate you letting us know and we will make all reasonable efforts to make that page or application more accessible for you.
            </p>

        </div>
        </div>
    )
}