import React from 'react'
import '../styles/catchphrase-section.css'

export default function CatchphraseSection() {

    const catchPhrase = 'Your hotel room in the middle of nowhere'

    return(
        <div className="catchphrase-section-container">
            <p>{catchPhrase}</p>
        </div>
    )
}