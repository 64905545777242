import React,  {useEffect, useState} from 'react';
import '../styles/contact-form.css';

export default function ContactForm(props) {
  const [ placeholder, setPlaceholder ] = useState('your email');
  const { name } = props;

  const onSubmit = (event) => {
    event.preventDefault();
    var xhr = new XMLHttpRequest();
    xhr.addEventListener('load', function () {
      event.target.classList.add('thanks');
      event.target.querySelector('input[type=email]').setAttribute('disabled', true);
    });
    xhr.open('POST', event.target.action);
    xhr.send(new FormData(event.target));
  };

  return (
    <form onSubmit={onSubmit} class="contact-form" data-netlify="true" method="post" name={name}>
      <input type="hidden" name="form-name" value={name} />
      <input type="email" name="Email" placeholder={placeholder} required />
      <span>Thanks! Coming to Atlanta soon.</span>
      <button></button>
    </form>
  )
}