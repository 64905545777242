import React from 'react';
import ContactForm from './contact-form';
import '../styles/stay-in-touch-form.css';

export default function StayInTouchForm() {
    const formTitle = 'Coming soon to Atlanta—stay in touch!';

    return (
        <>
            <div className="stay-in-touch-form-container">
                <p>{formTitle}</p>
                <ContactForm name="Stay in touch contact form" />
            </div>
            
        </>
    )
}