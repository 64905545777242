import React from 'react'
import FeaturesListItem from '../components/features-list-item.js'
import '../styles/features-list-section.css'

import FeaturesListImagesFireMobile from '../images/features-campfire-icon.svg'
import FeaturesListImagesKitchenMobile from '../images/features-kitchen-icon.svg'
import FeaturesListImagesNoCheckinMobile from '../images/features-mobilephone-icon.svg'
import FeaturesListImagesBathroomMobile from '../images/features-shower-icon.svg'
import FeaturesListImagesSpacedOutCabinsMobile from '../images/features-directions-icon.svg'

export default function FeaturesListSection() {

    const featuresData = [
        {
            image: FeaturesListImagesBathroomMobile,
            text: 'private bathroom'
        },
        {
            image: FeaturesListImagesKitchenMobile,
            text: `full kitchen`
        },
        {
            image: FeaturesListImagesSpacedOutCabinsMobile,
            text: 'spaced out cabins'
        },
        {
            image: FeaturesListImagesNoCheckinMobile,
            text: 'no checkin at desk'
        },
        {
            image: FeaturesListImagesFireMobile,
            text: 'private fire pits'
        },
    ]

    return (
        <section className="features-list-section-frame-container">
            <h1>Features</h1>
            <ul className="features-figures-container">
                {featuresData.map((item, index) => {
                    return <FeaturesListItem key={index} featureImage={item.image} featureText={item.text} />
                })}
            </ul>
        </section>
    )
}