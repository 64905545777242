import React from 'react'
import '../styles/benefits-section.css';
import SleepingImage from '../images/our-tiny-home-sleeping.png';
import KitchenImage from '../images/our-tiny-home-kitchen.png';
import NatureImage from '../images/our-tiny-home-nature.png';

export default function BenefitsSection() {
    const title = 'Relax in Your Own Tiny Home';
    const sleepingTitle = "Sleep soundly in nature";
    const kitchenTitle = "A Kitchen to Cook In";
    const natureTitle = "Answering Nature’s Call";
    return (
        <section className="benefits-section-container-frame">

            <div className="benefits-section-title-text-container">
                <h1>{title}</h1>
                <p>The Nested Cabin serves as the primary environment and touch point for our guests. It serves as their homebase and provides them a cozy environment that is nestled in nature but protected and comfortable. Our cabins are fabricated off site by a number of builders and hitched to the site for setup and connection.</p>
                <p>Our cabins are built to RVIA certification or similar to ensure campground/RV Park code compliance. Our cabins are split into 3 interior areas.</p>
            </div>

            <ul className="benefits-section-images-title-text-container">
                <li>
                    <article>
                        <img loading="lazy" className="benefits-section-sleeping-image" src={SleepingImage} alt=""/>
                        <h2>{sleepingTitle}</h2>
                        <p>Each cabin has a comfortable queen bed with fresh linens and pillows. Cabins for four have two queen beds which are sometimes lofted (so you can fight over the top bunk).</p>
                        <p>Our beds are always close to a window, so you can wake up with a view of the trees. </p>
                    </article>
                </li>
                <li>
                    <article>
                        <img loading="lazy" className="benefits-section-kitchen-image" src={KitchenImage} alt=""/>
                        <h2>{kitchenTitle}</h2>
                        <p>Each kitchen is equipped with a sink (with drinktable water), a mini-fridge, a stove, and cupboards filled with essentials for cooking and dining.</p>
                        <p>All you have to bring is the food itself.</p>
                    </article>
                </li>
                <li>
                    <article>
                        <img loading="lazy" className="benefits-section-nature-image" src={NatureImage} alt=""/>
                        <h2>{natureTitle}</h2>
                        <p>No outhouses here. The Nested cabins have a full bathroom.</p>
                        <p>The hot shower includes complementary natural and organic biodegradable shampoo, conditioner, and body wash. Bath towels are also provided.</p>
                    </article>
                </li>
            </ul>
        </section>
    )
}
