import React, {useEffect, useCallback} from 'react';
import '../styles/modal-content.css'
export default function PrivacyPolicyContent(props) {
    const {onClickCrossButton} = props;
    const escFunction = useCallback((event) => {
        const modalWindow = document.getElementById("modal-id");
        if(event.keyCode === 27) {
            onClickCrossButton();
        }
        if(event.target == modalWindow){
            onClickCrossButton();
        }

    }, []);

    useEffect(() => {
        document.addEventListener("keydown", escFunction, false);
        window.addEventListener("click", escFunction, false);
        return () => {
            document.removeEventListener("keydown", escFunction, false);
            window.removeEventListener("click", escFunction, false);
        };
    }, []);
    return (
        <div className="modal" id="modal-id">
            <div className="modal-content">
                <div className="cross-button-container">
                    <span onClick={onClickCrossButton} className="modal-cross-button"></span>
                </div>
                <h3>
                Privacy Policy<br/><br/>
                </h3>

                <h2>Last updated: January 1, 2021</h2>

                <h2>Effective date: January 1, 2021</h2>

                <p>
                This Privacy Policy (this “Privacy Policy”) applies to your access and use of the website located at www.nested.house, which we refer to as the “Site.” Use of the term “Site” also includes any content, functionality, and services offered on or through the Site.<br/><br/>

                YOU SHOULD CAREFULLY READ THIS PRIVACY POLICY. By accessing, browsing, or otherwise using the Site, you are creating a binding contract between you and us, and you are acknowledging that you have read, understood, and agreed to be bound by this Privacy Policy. If you do not agree with the terms of this Privacy Policy and would prefer that we not collect, use, or share your information as we describe, you should not use the Site or provide us with any information.<br/><br/>

                We may make changes to this Privacy Policy. Any modifications will be effective when the changes are posted to the Site. You can review the most current version of this Privacy Policy at any time by accessing the section of the Site labeled Privacy Policy, and you can determine when this Privacy Policy was last revised by referring to the “Last Updated” legend at the top of this Privacy Policy. You are expected to check this Privacy Policy from time to time to take notice of any changes. You understand and agree that your continued access to or use of the Site after any posted modification to this Privacy Policy indicates your acceptance of the modification, even if you did not take the time to read the modification.<br/>
                </p>

                <h2>Our Commitment to Privacy</h2>

                <p>
                The Site is owned and operated by nested House, Inc. (who we refer to, together with our affiliates, as “nested,” “us,” “we,” or “our,” depending in the context).<br/><br/>

                We take the privacy of users of this site seriously. References in this Privacy Policy to “you” and “your” refer to you as a visitor to and user of the Site.<br/><br/>

                This Privacy Policy explains:<br/>
                <ul>
                    <li>What information we collect, how we collect that information, and how we use that information,</li>
                    <li>When and with whom we may share information we collect, and</li>
                    <li>Your choices regarding your personal information.</li>
                </ul>

                This Privacy Policy applies only to information provided and collected through the Site. It does not apply to information that we or other parties may collect through other means, such as through any business relationship between us and you. This Privacy Policy also does not apply to information that you submit on other websites, even if we communicate with you on those sites, or provide a link to those sites through our Site. For example, if you post something on Facebook, LinkedIn, Instagram, Twitter, or other social media or networking sites, that information is governed by the privacy policies of those sites, not by this Privacy Policy.<br/>
                </p>

                <h2>Collection and Use of Personal Information</h2>

                <p>
                <b>How We Collect Personal Information</b><br/><br/>
                
                “Personal Information” refers to information that can be used to personally identify you, a specific individual. Different states and laws define Personal Information (or similar terms, such as “personally identifiable information”) differently, but Personal Information generally includes information such as your name, mailing address, e-mail address, and phone number. It may also include the IP address of your computer or mobile device if that IP address can be associated with other Personal Information and used to identify a particular person, or geo-location information, again if that information can be used to pinpoint a specific party.<br/><br/>

                We will collect Personal Information from you only if you voluntarily provide it to us. For example, when you contact us, such as through the “Contact Us” or similar links on the Site, or when you subscribe our resources, we will have access to your name, email address, and any other information you choose to include in your correspondence. If you participate in any other interactive area of our Site, we will collect any Personal Information that you include as a part of your participation.<br/><br/>

                In addition, some mobile network providers (“Mobile Provider”) in the U.S. are required to use technology that tracks the physical location of mobile devices that use their service. Depending on your Mobile Provider and mobile device, we may automatically receive this information, which, as noted above may be considered Personal Information. Most mobile devices provide users with the ability to disable location services. These controls are usually located in the mobile device's settings menu. If you have questions about how to disable your mobile device's location services, you should contact your Mobile Provider or your mobile device manufacturer.<br/><br/>

                <b>How We Use Personal Information</b><br/><br/>

                We use the Personal Information we collect through the Site to respond to your inquiries, to provide you with the resources and services you request, and to contact you regarding other services we offer that may be of interest to you. We may also use Personal Information collected through the Site for research regarding the effectiveness of the Site and our business planning, marketing, advertising, and sales efforts. In addition, if we begin to offer services that use location tracking (“pinpointing”) information, then if we have your “opt-in” consent to do so we may use and store that information to provide location-based services, including location-targeted advertising.<br/><br/>

                We may combine and enhance the Personal Information we receive with information we receive from third parties as long as we use the combined information as described in this Privacy Policy. In addition, we may use your Personal Information to generate non-personal information, such as demographic information that helps us understand the general characteristics of our users. When we do so, we will take reasonable measures to ensure that the information is no longer personally identifiable and cannot later be used to identify you.<br/><br/>

                You are in control over whether you provide us with any Personal Information. If you are not comfortable with our accessing and using the Personal Information as described, you should not provide it (and should take steps to disable any technology that automatically provides us with Personal Information). This may affect our ability to provide you with information and services through the Site; however, that is your choice.<br/>
                </p>

                <h2>Collection and Use of Non-Personal Information</h2>

                <p>
                <b>How We Collect Non-Personal Information</b><br/><br/>
                
                Whenever you interact with the Site, we automatically receive and record information from your browser, such as your IP address, the type of browser or application you are using to access the Site, the identity of the Site page or feature you are requesting. None of this information, on its own, is considered Personal Information (unless and until it is actually combined with Personal Information in a way that would allow someone to identify a specific individual), and we refer to it in this Privacy Policy as “NPI.”<br/><br/>

                Our Site may use “cookies” or other tracking technologies to track how you use the Site. We also may send instructions to your computer or device using JavaScript or other computer languages to gather the sorts of information described above and other details about your interactions with the Site. A cookie is a file stored on your computer or device to uniquely identify your browser or to store information or settings on your computer or device. If applicable, our advertising partners may also transmit cookies to your browser, application, or mobile device when you click on ads that appear on the Site. Clicking on a link to a third party website from our Site may also allow that third party website to also transmit cookies to you.<br/><br/>

                We may also use “web beacons”, i.e. “web bugs” or “single–pixel” or “clear” GIFs, on the Site. Web beacons allow ad networks to provide anonymized, aggregated auditing, research, and reporting for us and for advertisers. Web beacons also enable ad networks to serve targeted advertisements to you when you visit other websites. Because your web browser must request these advertisements and web beacons from the ad network’s servers, these companies can view, edit, or set their own cookies, just as if you had requested a web page from their website.<br/><br/>

                You may adjust your web browser software if you do not wish to receive cookies or web beacons, but this may prevent you from taking advantage of some of the Site’s features. Please refer to your browser or email software instructions or help screen to learn more about these functions. You may also request to opt-out of an advertiser’s use of cookies by visiting the Network Advertising Initiative or the Digital Advertising Alliance. You may request to opt-out of Google’s use of cookies by visiting www.google.com/ads/preferences. Please note that your request to opt out will be subject to all applicable terms on those third-party websites, in addition to this Privacy Policy.<br/><br/>

                <b>How We Use Non-Personal Information</b><br/><br/>
                
                In addition to the uses described above for automatically-collected NPI, we may use this information to analyze trends, administer the Site, and gather broad demographic data for aggregate use.<br/><br/>

                We may also use NPI and other de-identified information (i.e. information that cannot be used to identify you) for any lawful business purpose without any obligation or accounting to you. For example, we can use NPI for developing products, services, and providing those offerings to other users and third parties.<br/>
                </p>

                <h2>How We May Share Information</h2>
                <p>
                    We may share information with third parties in the following
                    circumstances:
                    <ul>
                    <li>
                        We may share your information, including Personal Information, so
                        that we or others may provide you with periodic mailings, or may
                        contact you regarding products, services, or events. If you do not
                        wish for us to share your information in this manner, please let us
                        know by contacting us at hi@nested.house.
                    </li>
                    <li>
                        We may employ other companies and people to perform tasks on our
                        behalf and we may need to share Personal Information with them to
                        provide services to you. For example, such parties may handle our
                        data management and/or email distribution. Unless we tell you
                        differently, they do not have any right to use your Personal
                        Information beyond what is necessary to assist us.
                    </li>
                    <li>
                        We may share information, including Personal Information, with our
                        corporate affiliates for our and their legitimate business purposes.
                    </li>
                    <li>
                        We will share Personal Information with third parties for other
                        purposes if we have your express “opt-in” consent to do so.
                    </li>
                    <li>
                        We may disclose non-personal, aggregate, anonymous data in a
                        de-identified format for any legitimate business purpose.
                    </li>
                    <li>
                        We may disclose Personal Information at the request of law
                        enforcement or government agencies or in response to subpoenas,
                        court orders, or other legal process to establish, protect, or
                        exercise our legal or other rights or to defend against a legal
                        claim, or as otherwise required or allowed by law.
                    </li>
                    <li>
                        We may disclose Personal Information if we believe that such action
                        is appropriate to (a) protect the rights, property, or safety of a
                        user of the Site or any other person, (b) investigate or prevent a
                        violation by you of any contractual or other relationship with us,
                        (c) investigate or prevent your actual or potential illegal,
                        fraudulent, or harmful activity, or (d) detect, prevent, or
                        otherwise address fraud, security, or technical issues.
                    </li>
                    <li>
                        We may share information, including Personal Information, in
                        connection with, during negotiations of, or as a part of the closing
                        of any merger, sale of company assets, financing, or acquisition of
                        all or a portion of our business to another company.
                    </li>
                    </ul>
                    We do not sell, rent, or lease any Personal Information that we collect
                    about you through the Site to anyone for any purpose, other than as
                    disclosed.
                </p>
                <h2>How We Protect Your Information</h2>
                <p>
                    We use commercially reasonable security measures to prevent unauthorized
                    persons from accessing our files or tampering with our technology and to
                    secure your Personal Information from accidental loss and from
                    unauthorized access, use, alteration, and disclosure. However we cannot
                    and do not guarantee the security of any information. This is especially
                    true for any information that is transmitted electronically and that is
                    unprotected until it reaches our servers.
                </p>
                <h2>Your Communication Preferences</h2>
                <p>
                    Correcting, Updating, Accessing, or Removing Personal Information
                    <br />
                    <br />
                    If you want to correct, update, or remove any Personal Information that
                    you have provided to us, or if you no longer want to receive information
                    from us, you can contact us at hi@nested.house . Please note that we
                    may not be able to completely remove Personal Information from our
                    systems in certain circumstances, such as if it may be necessary to
                    prevent fraud or future abuse, if required by law, or as retained in our
                    data backup systems or cached or archived pages. All of your Personal
                    Information that we keep will continue to be subject to the terms of the
                    Privacy Policy to which you have previously agreed.
                    <br />
                    <br />
                    In addition, please note that notwithstanding your email preferences, we
                    may need to contact you as required by law, or to complete any open
                    inquiries.
                    <br />
                    <br />
                    <b>Your California Privacy Rights</b>
                    <br />
                    <br />
                    If you are a California resident, please see the section below entitled
                    “Additional Information for California Residents.”
                </p>
                <h2>Do Not Track Requests; Third Party Services</h2>
                <p>
                    <b>Do Not Track</b>
                    <br />
                    <br />
                    Currently, certain browsers (including Google Chrome, Safari, Firefox,
                    and Internet Explorer) offer a “no not track” (“DNT”) option. The DNT
                    option sends a signal to websites visited by the user about the user’s
                    DNT preference, if any, set on the browser. We do not currently commit
                    to responding to browsers’ DNT signals. We cannot make this commitment
                    because no common industry standard for DNT has been adopted by industry
                    groups, technology companies, or regulators.
                    <br />
                    <br />
                    <b>Third Party Services</b>
                    <br />
                    <br />
                    Some of our Site features may be served by third parties, including
                    advertisers, ad networks and servers, content providers, and application
                    providers. These third parties may use cookies and/or web beacons and/or
                    other tracking technology when you use our Site. These third parties may
                    be able to associate the information they collect with your Personal
                    Information, or they may collect information about your online
                    activities over time and across other websites and online services. They
                    may use this information to provide you with behavioral advertising or
                    other targeted content. We cannot control third parties’ tracking
                    technologies or how they may be used. If you have any questions about an
                    advertisement or other targeted content, you should content the
                    responsible provider directly.
                </p>
                <h2>Users from Outside the United States</h2>
                <p>
                    Our Site is intended for a United States audience, is hosted in the
                    United States and is governed by United States law. If you are using the
                    Site from outside the United States, please be aware that your
                    information may be transferred to, stored, and processed in the United
                    States where our servers are located and our central database is
                    operated. The data protection and other laws of the United States and
                    other countries might not be as comprehensive as those in your country.
                    By using the Site, you consent to your information being transferred to
                    our facilities and to the facilities of those third parties with whom we
                    share it as described in this Privacy Policy.
                </p>
                <h2>A Note About Children</h2>
                <p>
                    Our Site is not targeted to, and we have no interest in collecting
                    information from, children, and we do not knowingly gather any Personal
                    Information from children under the age of 13. If you are a parent or a
                    legal guardian who believes that your minor child provided us with any
                    Personal Information, please contact us at hi@nested.house.
                </p>
                <h2>Questions About Our Privacy Policy</h2>
                <p>
                    If you have any questions about this Privacy Policy, please contact us
                    at hi@nested.house. If you are a California resident, please also
                    see the section below entitled “Additional Information for California
                    Residents.”
                </p>
                <table>
                    <tr>
                        <th>Category of Personal Information We Collect</th>
                        <th>Examples</th>
                        <th>Where Obtained (i.e., the Categories of Sources of the Information)</th>
                        <th>Business or Commercial Purpose of Collection</th>
                    </tr>
                    <tr>
                        <td>Identifiers</td>
                        <td>Real name, postal address, unique personal identifier, online identifier, Internet Protocol address, email address, driver’s license number, passport number, or other, similar, identifiers.</td>
                        <td>
                            <ul>
                                <li>Directly from the consumer</li>
                                <li>Indirectly, from a friend of the consumer who provides us with an email referral</li>
                            </ul>
                        </td>
                        <td>
                            <ul>
                                <li>Communication with consumers in connection with bookings</li>
                                <li>Marketing efforts</li>
                            </ul>
                        </td>
                    </tr>
                    <tr>
                        <td>Personal information categories listed in the California Consumer Records statute (Cal. Civ. Code §1798.80(e))</td>
                        <td>Name, signature, address, telephone number, passport number, driver’s license or state identification card number, insurance policy number, education, education, employment, employment history, bank account number, credit card number, debit card number, or any other financial information, medical information, or health insurance information. Some personal information included in this category may overlap with other categories.</td>
                        <td>
                            <ul>
                                <li>Directly from the consumer</li>
                                <li>Indirectly, from a friend of the consumer who provides us with an email referral</li>
                            </ul>
                        </td>
                        <td>
                            <ul>
                                <li>Communication with consumers in connection with bookings</li>
                                <li>Facilitation of payment for bookings and other purchases</li>
                                <li>Accommodation of special requests with respect to bookings</li>
                                <li>Marketing efforts</li>
                                <li>Evaluation of and response to employment applications</li>
                            </ul>
                        </td>
                    </tr>
                    <tr>
                        <td>Protected classification characteristics under California or federal law</td>
                        <td>Veteran or military status.	</td>
                        <td>
                            <ul>
                                <li>Directly from the consumer</li>
                                <li>Indirectly through online verification tools</li>
                            </ul>
                        </td>
                        <td>Provision of veteran/active duty military discounts</td>
                    </tr>
                    <tr>
                        <td>Commercial information</td>
                        <td>Records of products or services purchased, obtained, or considered, or other purchasing or consuming histories or tendencies.</td>
                        <td>Directly from the consumer</td>
                        <td>
                            <ul>
                                <li>Communication with consumers in connection with bookings</li>
                                <li>Marketing efforts</li>
                                <li>Maintenance of historical business records</li>
                            </ul>
                        </td>
                    </tr>
                    <tr>
                        <td>Internet or other similar network activity</td>
                        <td>Browsing history, search history, information on interaction with a website, application, or advertisement.</td>
                        <td>Indirectly through Site activity (page views and form field entry) from consumer’s IP address</td>
                        <td>
                            <ul>
                                <li>Assessment of marketing efforts and evaluation of relevance of, interest in, and engagement with content</li>
                                <li>Maintaining and debugging our Site</li>
                            </ul>
                        </td>
                    </tr>
                    <tr>
                        <td>Professional or employment-related information</td>
                        <td>Current or past job history or performance evaluations.</td>
                        <td>
                            <ul>
                                <li>Directly from the consumer</li>
                                <li>Indirectly through online job search and document sharing tools</li>
                            </ul>
                        </td>
                        <td>Evaluation of and response to employment applications</td>
                    </tr>
                    <tr>
                        <td>Inferences drawn from other personal information</td>
                        <td>Profile reflecting a consumer’s personal preferences.</td>
                        <td>Directly from the consumer, through communication regarding stay preferences</td>
                        <td>Accommodation of special requests with respect to bookings</td>
                    </tr>
                </table><br/>
                <b>Parties To Whom We Disclose Information</b><br/>
                <p>The section of our Privacy Policy above entitled “How We may Share Information” generally describes how and why we share personal information. We primarily share information with service providers (as defined in the CCPA) and other businesses with whom we have contractual relationships, and that personal information is shared in the ordinary course of our business relationship with those parties.<br/></p>
                <p>We also share personal information with the following categories of third parties:</p>
                <ul>
                    <li>Analytics providers, like Google Analytics, who may use tracking technologies to measure the effectiveness of our Site. In connection with their analytics services, they may receive information about your internet or other similar network activity.</li>
                    <li>Verification services, like IDMe and SheerID, who confirm veteran/active military status for us when relevant to a booking.</li>
                </ul>
                <b>We do not sell personal information to third parties.</b><br/><br/>
                <b>Rights of California Consumers Under the CCPA</b><br/>
                <p>The CCPA provides California residents with specific rights regarding their personal information. If you are a California resident, you have the rights described below. Please note that your rights are subject to limitations and exceptions described in the CCPA, which we will provide more detail about if and when you make a CCPA-related request.</p>
                <ul>
                    <li><b>Right to Access Certain Information </b>- You have the right to ask us to provide you with information about our collection and use of your personal information over the past 12 months.</li>
                    <li><b>Right to Data Portability </b>- You have the right to ask us to provide you with copies of the personal information we have collected about you, in a portable and readily-usable format.</li>
                    <li><b>Rights to Request Deletion </b>of Certain Information - You have the right to ask us to delete personal information about you that we collected from you and have retained.</li>
                    <li><b>Right to Non-Discrimination </b>- You have the right not to be discriminated against for exercising any of your CCPA rights.</li>
                </ul>
                <b>How To Exercise Your Rights Under the CCPA</b><br/>
                <p>To exercise your rights described above, you must submit a verifiable consumer request to us by either:</p>
                <ul>
                    <li>Contacting us through the Site at https://nested.house/contact;</li>
                    <li>Calling us (toll-free) at 617-914-0021; or</li>
                    <li>Emailing us at hi@nested.house.</li>
                </ul>
                <p>Only you, or a person registered with the California Secretary of State that you expressly authorize to act on your behalf, can make a verifiable consumer request related to your personal information. You may also make a verifiable consumer request on behalf of your minor child.<br/><br/>

                You may only make a verifiable consumer request for access or data portability twice within any 12-month period.<br/><br/>

                We cannot respond to your request or provide you with information if we cannot identify you or confirm your authority to make the request, so any request will require you to provide sufficient information to allow us to reasonably verify your identity. We will only use any personal information provided in a verifiable consumer request to verify the requestor’s identity or authority to make the request.<br/><br/>

                We will respond to any verifiable consumer requests in the manner and within the timeframes required by the CCPA.<br/></p>
                <b>California’s “Shine the Light” Law</b><br/>
                <p>Aside from the CCPA, California’s “Shine the Light” law permits users that are residents of California to request the following information regarding our disclosure of your Personal Information to third parties for those third parties’ direct marketing purposes (a) a list of certain categories of personal information that we have disclosed to certain third parties for their direct marketing purposes during the immediately preceding calendar year; (b) the identity of certain third parties that received personal information from us for their direct marketing purposes during that calendar year; and (c) examples of the products or services marketed (if we have that information). If you are a California resident and would like to make such a request, please email us at <a href="mailto:hi@nested.house">hi@nested.house</a>.</p>

            </div>
        </div>
    )
}