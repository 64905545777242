import React from "react"
import "../styles/faq-section.css"

export default function FaqSection() {

    const faqs = [
        {
            question: "What's included in our tiny houses?",
            answer: function () {
                return <>
                    <p>Our tiny cabins have everything you need and nothing you don't.</p>
                    <p>Each cabin includes:</p>
                    <ul>
                        <li>a comfy queen sized bed or queen bunks with fresh linens</li>
                        <li>a bathroom with a hot shower and toilet</li>
                        <li>heat and air conditioning</li>
                        <li>a kitchenette with a mini fridge, two burner stove, dishware, and cookware</li>
                        <li>a mini library</li>
                        <li>an outdoor fire pit with grilling grate and outdoor seating</li>
                        <li>provisions available for purchase</li>
                        <li>a cell phone lockbox so you can tuck your phone away and enjoy undistracted time in nature</li>
                        <li>our cabins are also dog friendly, so if you book with your pup, we provide dog bowls, treats, a waste bag, and an outdoor lead</li>
                    </ul>
                </>
            }
        },
        {
            question: "How close are the cabins to each other?",
            answer: function () {
                return <>
                    <p>No answer here.</p>
                </>
            }
        },
        {
            question: "Do you have a referral program?",
            answer: function () {
                return <>
                    <p>No answer here.</p>
                </>
            }
        },
    ]

    const faqId = (index) => {
        return `faq${index}`;
    }

    return (
        <section className="faq-section-container">
            <h1>Got Questions? We have answers!</h1>
            <ul className="faq-section-accordion-container">
                {faqs.map(function (faq, index) {
                    return (
                        <li>
                            <input type="checkbox" id={faqId(index)} />
                            <h2><label for={faqId(index)}>{faq.question}</label></h2>
                            <div>{faq.answer()}</div>
                        </li>
                    );
                })}
            </ul>
        </section>
    )
}