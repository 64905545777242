import React from 'react'
import '../styles/_content-section.css'
import '../styles/who-we-are-section.css';
import WhoWeAreImage from '../images/WhoWeAre.png'

export default function WhoWeAreSection() {
    const title = 'Nested: Your Escape Back to Nature';
    return (
        <section className="content-section who-we-are-container-frame">
            <img loading="lazy" src={WhoWeAreImage} alt="Nested: Your Escape Back to Nature"/>
            <div>
                <h1>{title}</h1>
                <p>Escape to a tiny cabin, nestled in a beautiful nature setting.</p> 
                <p>With Nested, you have your own quiet space to slow down, unplug, and disconnect from work and everyday stresses.</p>
                <p>Spend your next getaway indulging in nature and mindful moments.</p>
            </div>
        </section>
    )
}
