
import * as React from "react"

import "../styles/layout.css"
import HeroBanner from "./hero-banner"
import StayInTouchForm from "./stay-in-touch-form"
import WhoWeAreSection from "./who-we-are-section"
import OurStorySection from "./our-story-section"
import CatchphraseSection from "./catchphrase-section"
import BenefitsSection from "./benefits-section"
import FaqSection from "./faq-section"
import Footer from "./footer"
import FeaturesListSection from "./features-list-section"
import PrivacyPolicyContent from './privacy-policy-content'
import TermsAndConditionsContent from './terms-and-conditions-content'

const Layout = () => {
  const [renderPrivacyPolicy, setRenderPrivacyPolicy] = React.useState(false);
  const [renderTermsContent, setRenderTermsContent] = React.useState(false);
  const renderPolicy = () => {
    setRenderPrivacyPolicy(true);
  }
  const renderTerms = () => {
    setRenderTermsContent(true);
  }
  const onClickCrossButton = () => {
    setRenderPrivacyPolicy(false);
    setRenderTermsContent(false);
  }
  return (
    <div className="layout-container">
      <HeroBanner />
      <div className="layout-content-container">
        <StayInTouchForm />
        <CatchphraseSection />
        <FeaturesListSection />
        <div className="order">
          <div className="our-story">
            <OurStorySection />
          </div>
          <div className="who-we-are">
            <WhoWeAreSection />
          </div>
          <div className="benefits">
            <BenefitsSection />
          </div>
        </div>
        <FaqSection/>
      </div>
      <Footer renderPolicy={renderPolicy} renderTerms={renderTerms}/>
      { renderPrivacyPolicy &&
          <PrivacyPolicyContent onClickCrossButton={onClickCrossButton}/> 
      }
      { renderTermsContent && 
          <TermsAndConditionsContent onClickCrossButton={onClickCrossButton}/>
      }
    </div>
  )
}

export default Layout
