import React from 'react'
import '../styles/_content-section.css';
import '../styles/our-story-section.css';
import OurStory from '../images/our-story-image.png'

export default function OurStorySection() {
    const title = 'Our Story';
    return (
        <section className="content-section our-story-container-frame">
            <img loading="lazy" className="our-story-image" src={OurStory} alt=""/>
            <div>
                <h1>{title}</h1>
                <p>We started Nested because, quite simply, we work too much. We spend too much time glued to a screen, and don’t invest enough time enjoying our own lives. </p>
                <p>Nested is our chance to hit pause and reconnect with nature. </p>
                <p>We want to create a simple escape for those who want to reconnect with what matters most in life. No WiFi, no notifications, no distractions. A place where you can find joy and delight in those rare, serene moments of silence and solitude. </p>
                <p>We want to live more mindful lives, and Nested is an opportunity for us to help others who want to do the same. </p>
            </div>
        </section>
    )
}
