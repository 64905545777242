import React from 'react'
import ContactForm from './contact-form';
import '../styles/footer.css';
import NestedLogoGreen from '../images/nested-logo-green.svg'


export default function Footer(props) {
    const {renderPolicy, renderTerms} = props;
    
    return (
        <div className="footer-container">
            <div className="desktop">
                <div className="desktop-left">
                    <img loading="lazy" src={NestedLogoGreen} alt="footer-logo-green"/>
                    <ContactForm name="Footer contact form" />
                </div>
                <div className="desktop-right">
                    <nav>
                        <a href="mailto:press@nested.house">Press</a>
                        <a href="mailto:careers@nested.house">Careers</a>
                    </nav>
                    <a href="mailto:hi@nested.house" className="email-text-container">hi@nested.house</a>
                    <div className="copyrigths-container">
                        <p href="">© 2021 Nested House. All Rights Reserved.</p>
                        <p><span onClick={renderPolicy}>Privacy Policy </span>|  <span onClick={renderTerms}>Terms and Conditions</span></p>
                    </div>
                </div>
            </div>
        </div>
    )
}