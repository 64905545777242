import React from 'react'
import '../styles/hero-banner.css';
import HeroBannerImageMobile from '../images/nested-logo-white-mobile.png';
import HeroBannerImageDesktop from '../images/nested-logo-white-desktop.svg'

export default function HeroBanner() {
    const title = 'Where you come to hide out';
    return (
        <div className="hero-banner-container">
            <img loading="lazy" src={HeroBannerImageDesktop} alt="Nested Logo"/>
            <p>{title}</p>
        </div>
    )
}
