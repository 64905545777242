import React from 'react'
import '../styles/features-list-item.css'

export default function FeaturesListItem(props) {
    const {
        featureImage,
        featureText,
    } = props;

    return (
        <li className="features-list-item-frame-container">
            <img loading="lazy" src={featureImage} alt={featureText}/>
            <p>{featureText}</p>
        </li>
    )
}